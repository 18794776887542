import { html, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Label } from '../../mixins/Label.js'
import { Disabled } from '../../mixins/Disabled.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { SlotController } from '../../controllers/SlotController.js'
import { consume } from '@lit/context'
import { classMap } from 'lit/directives/class-map.js'
import { defaultTabsNextContext, tabsNextContext } from '../one-ux-tabs-next/TabsNextContext.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Nullable, Optional } from '../../types.js'
import { Errors } from '../../mixins/Errors.js'
import { ErrorsPopout } from '../../fragments/ErrorsPopout.js'
import { PurposeFactory } from '../../mixins/Purpose.js'
import { getDefaultMutexWriteContext, mutexWriteContext } from '../../contexts/MutexContext.js'
import { OneUxTabpanelNextElement } from '../one-ux-tabpanel-next/OneUxTabpanelNextElement.js'
import { InternalOneUxTabsIndicator } from '../one-ux-tabs-next/InternalOneUxTabsIndicator.js'
import { ChangeEvent } from './events.js'
import { resizeProperty, UpdateOnResizedController } from '../../controllers/UpdateOnResizedController.js'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const Purpose = PurposeFactory({ purposes: ['default', 'ai', 'placeholder'] })

const BaseClass = Purpose(Errors(Disabled(Focusable(Label(Styled(OneUxElement))))))

@customElement('one-ux-tab-next')
export class OneUxTabNextElement extends BaseClass {
  /** @internal */
  @state()
  @consume({ context: tabsNextContext, subscribe: true })
  _tabsContext = defaultTabsNextContext

  @consume({ context: mutexWriteContext<OneUxTabNextElement>(), subscribe: true })
  private _mutexWriteContext = getDefaultMutexWriteContext<OneUxTabNextElement>()

  @property({ type: Boolean, reflect: true })
  public accessor active!: boolean

  @property({ type: String })
  public accessor name: Optional<string>

  @property({ type: String, reflect: true })
  public accessor id: string = this.#generateId()

  /** @internal */
  @state()
  accessor _activeIndicatorElement: Nullable<InternalOneUxTabsIndicator> = null

  /** @internal */
  @state()
  accessor _tabpanelElement: Nullable<OneUxTabpanelNextElement> = null

  /** @internal */
  @state()
  accessor _isHovered = false

  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['start', 'end', 'adornment']
  })

  constructor() {
    super()
    new UpdateOnResizedController(this)
  }

  connectedCallback() {
    super.connectedCallback()

    this.role = 'tab'

    this._mutexWriteContext.register({
      element: this,
      set: (force: boolean) => {
        if (force || (this.name && this.purpose !== 'placeholder')) {
          this.active = true
          return true
        }
        return false
      },
      reset: () => {
        this.active = false
      }
    })
  }

  disconnectedCallback(): void {
    this._mutexWriteContext.unregister(this)
    this._tabpanelElement?.removeAttribute('aria-labelledby')
    super.disconnectedCallback()
  }

  public click() {
    this.#activateTab()
  }

  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('active')) {
      if (this.active) {
        this._mutexWriteContext.lock(this)
      } else {
        this._mutexWriteContext.release(this)
      }
    }

    if (changed.has('id') && (this.id == null || this.id === '')) {
      this.id = this.#generateId()
    }
  }

  guardedRender() {
    const shouldShowHoverIndicator = !this.disabled && !this.active && this._isHovered

    return html`<div
        class=${classMap({
          'one-ux-element--root': true,
          [`is-weight-${this._tabsContext.weight}`]: true,
          'has-accessibility-styling': this._tabsContext.hasAccessibilityStyling
        })}
        one-ux-tooltip=${ifDefined(this.#labelTooltip)}
        ?one-ux-tooltip-custom-aria=${!!this.#labelTooltip}
        @mousedown=${() => (this._isHovered = false)}
        @click=${this.#activateTab}
        @mouseenter=${() => (this._isHovered = true)}
        @mouseleave=${() => (this._isHovered = false)}
      >
        <slot name="start"></slot>
        ${this.#aiIconFragment()}
        <slot></slot>
        ${this.#slots.hasDefaultSlot() ? null : html`<span class="label">${this.label}</span>`}
        <slot name="end"></slot>
        ${this.#pillFragment('end')}
        <div class="adornments">
          ${this.#pillFragment('adornment')}
          <slot name="adornment"></slot>
        </div>
        ${shouldShowHoverIndicator
          ? html`<internal-one-ux-tabs-indicator
              variant="hover"
              .purpose=${this.purpose}
              indicator-width="100%"
              indicator-left="0"
            ></internal-one-ux-tabs-indicator>`
          : null}
      </div>
      ${ErrorsPopout({
        reference: 'previous',
        errors: this.disabled ? [] : this.errors,
        hidden: this.hideErrors
      })}`
  }

  protected async updated(changed: PropertyValues<this & resizeProperty>): Promise<void> {
    if (changed.has('active') && changed.get('active') !== this.active) {
      this.dispatchEvent(new ChangeEvent())
    }

    if (changed.has('_tabsContext') || changed.has('name')) {
      this.toggleAttribute('state-implicit', this._tabsContext.implicit)

      this._activeIndicatorElement = this._tabsContext.getActiveIndicator()

      if (this.name) {
        this._tabpanelElement = this._tabsContext.getTabpanelElement(this.name)
      }
    }

    if (changed.has('label')) {
      this.setAttribute('aria-label', this.label)
    }

    if (
      changed.has('active') ||
      changed.has('_activeIndicatorElement') ||
      changed.has('purpose') ||
      changed.has('label') ||
      changed.has('__resize__')
    ) {
      this.setAttribute('aria-selected', JSON.stringify(!!this.active))

      if (this.active && this._activeIndicatorElement) {
        await Promise.all([
          new Promise(requestAnimationFrame),
          // TODO: this will delay the initial indicator when on slow network (e.g. trains...)
          document.fonts ? document.fonts.ready : Promise.resolve()
        ])

        if (this.active) {
          // TODO: we'd rather find the closest container with "position: relative" but this is currently the resonable solution
          const $parent = this._activeIndicatorElement.parentElement!
          const tabRect = this.getBoundingClientRect()

          this._activeIndicatorElement.purpose = this.purpose
          this._activeIndicatorElement.noAnimation = changed.has('__resize__')
          this._activeIndicatorElement.indicatorWidth = `${tabRect.width}px`
          this._activeIndicatorElement.indicatorLeft = `${tabRect.left - $parent.getBoundingClientRect().left + $parent.scrollLeft}px`

          this._activeIndicatorElement.addEventListener(
            'transitionend',
            () => {
              if (this._activeIndicatorElement) {
                this._activeIndicatorElement.noAnimation = true
              }
            },
            { once: true }
          )
        }
      }
    }

    if (changed.has('disabled')) {
      if (this.disabled) {
        this.setAttribute('aria-disabled', JSON.stringify(true))
      } else {
        this.removeAttribute('aria-disabled')
      }
    }

    if (changed.has('name') && this.name !== changed.get('name')) {
      this._tabsContext.namedChanged()
    }

    if (
      changed.has('name') ||
      changed.has('_tabpanelElement') ||
      changed.has('id') ||
      (this._tabsContext.hasFixedContent && changed.has('active'))
    ) {
      if (!this._tabpanelElement) {
        const oldTabpanelElement = changed.get('_tabpanelElement')

        if (oldTabpanelElement?.getAttribute('aria-labelledby') === this.id) {
          oldTabpanelElement.removeAttribute('aria-labelledby')
        }
      }

      if (this._tabpanelElement?.id) {
        this.setAttribute('aria-controls', this._tabpanelElement.id)

        if (!this._tabsContext.hasFixedContent) {
          this._tabpanelElement.setAttribute('aria-labelledby', this.id)
        } else if (this.active) {
          this._tabpanelElement.setAttribute('aria-labelledby', this.id)
        }
      } else {
        this.removeAttribute('aria-controls')
      }
    }
  }

  #generateId() {
    return `tab-${crypto.randomUUID()}`
  }

  #aiIconFragment() {
    return !this.#slots.hasNamedSlot('start') && this.purpose === 'ai'
      ? html`<one-ux-icon set="ai" icon="ai-powered" size="icon-100" purpose="ai"></one-ux-icon>`
      : null
  }

  #pillFragment(placement: 'adornment' | 'end') {
    if (!this.errors.length) {
      return null
    }

    if (!this._tabsContext.implicit && placement === 'adornment') {
      return html`<one-ux-pill purpose="caution"></one-ux-pill>`
    }

    return this._tabsContext.implicit && placement === 'end'
      ? html`<one-ux-pill purpose="caution" style="align-self: end"></one-ux-pill>`
      : null
  }

  get #labelTooltip() {
    if (this.#slots.hasSingleDefaultSlot('one-ux-icon')) {
      return this.label
    }
  }

  async #activateTab() {
    if (this.disabled || !this.name || this.active) return

    if (!this._tabsContext.beforeActivate(this.name)) return
    this.active = true
    await this.updateComplete
    this._tabsContext.activated()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tab-next': OneUxTabNextElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tab-next': OneUxTabNextElement
    }
  }
}
