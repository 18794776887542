import { LanguageSets, getLangCode } from '../../utils/getLangCode.js'

export type LanguageSet = {
  scrollLeft: string
  scrollRight: string
  header: string
}

const languages: LanguageSets<LanguageSet> = {
  en: {
    scrollLeft: 'Scroll left',
    scrollRight: 'Scroll right',
    header: 'Tab header'
  },
  sv: {
    scrollLeft: 'Rulla åt vänster',
    scrollRight: 'Rulla åt höger',
    header: 'Tabbhuvud'
  },
  nb: {
    scrollLeft: 'Rull til venstre',
    scrollRight: 'Rull til høyre',
    header: 'Faneoverskrift'
  },
  fi: {
    scrollLeft: 'Vieritä vasemmalle',
    scrollRight: 'Vieritä oikealle',
    header: 'Välilehden otsikko'
  },
  da: {
    scrollLeft: 'Rul til venstre',
    scrollRight: 'Rul til højre',
    header: 'Fanehoved'
  }
}

export const getLanguage = (element: Element) => {
  const key = getLangCode(element)
  return { languageKey: key, languageSet: languages[key] }
}
