import { OneUxElement } from '../../OneUxElement.js'
import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { styleMap } from 'lit/directives/style-map.js'
import { classMap } from 'lit/directives/class-map.js'
import { style } from './style.js'
import { createRef, Ref, ref } from 'lit/directives/ref.js'
import { PositionController } from './PositionController.js'
import { MouseDownHandler } from './MouseDownHandler.js'
import { MouseWheelHandler } from './MouseWheelHandler.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { Implicit } from '../../mixins/Implicit.js'
import { Weight } from '../../mixins/Weight.js'

const Styled = StyledFactory(style)

const BaseClass = Implicit(Weight(Styled(OneUxElement)))

/**
 * An alternative to the default scrollbar that has OneUx-specific styling applied to it.
 */
@customElement('one-ux-scroll')
export class OneUxScrollElement extends BaseClass {
  #refs: ChildRefs = {
    $content: createRef(),
    $scrollbarY: createRef(),
    $scrollbarX: createRef(),
    $handleY: createRef(),
    $handleX: createRef()
  }

  #positionController = new PositionController(this, this.#refs)
  #mouseDownHandler = new MouseDownHandler(this.#refs)
  #mouseWheelHandler = new MouseWheelHandler(this.#refs)

  /**
   * Indents the content to fit the scrollbars.
   */
  @property({ type: Boolean })
  public accessor gutter = false

  public get scrollTop() {
    if (this.#refs.$content.value) {
      return this.#refs.$content.value.scrollTop
    }
    return 0
  }

  public set scrollTop(value: number) {
    if (this.#refs.$content.value) {
      this.#refs.$content.value.scrollTop = value
    }
  }

  public get scrollLeft() {
    if (this.#refs.$content.value) {
      return this.#refs.$content.value.scrollLeft
    }
    return 0
  }

  public set scrollLeft(value: number) {
    if (this.#refs.$content.value) {
      this.#refs.$content.value.scrollLeft = value
    }
  }

  public get scrollWidth() {
    if (this.#refs.$content.value) {
      return this.#refs.$content.value.scrollWidth
    }
    return 0
  }

  public get scrollHeight() {
    if (this.#refs.$content.value) {
      return this.#refs.$content.value.scrollHeight
    }
    return 0
  }

  public get scrollRootElement() {
    return this.#refs.$content.value
  }

  public scroll(options?: ScrollToOptions): void
  public scroll(x: number, y: number): void
  public scroll(xOrOptions?: number | ScrollToOptions, y?: number): void {
    if (this.#refs.$content.value) {
      if (typeof y !== 'undefined') {
        this.#refs.$content.value.scroll(xOrOptions as number, y)
      } else {
        this.#refs.$content.value.scroll(xOrOptions as ScrollToOptions)
      }
    }
  }

  public scrollTo(options?: ScrollToOptions): void
  public scrollTo(x: number, y: number): void
  public scrollTo(xOrOptions?: number | ScrollToOptions, y?: number): void {
    if (this.#refs.$content.value) {
      if (typeof y !== 'undefined') {
        this.#refs.$content.value.scrollTo(xOrOptions as number, y)
      } else {
        this.#refs.$content.value.scrollTo(xOrOptions as ScrollToOptions)
      }
    }
  }

  public scrollBy(options?: ScrollToOptions): void
  public scrollBy(x: number, y: number): void
  public scrollBy(xOrOptions?: number | ScrollToOptions, y?: number): void {
    if (this.#refs.$content.value) {
      if (typeof y !== 'undefined') {
        this.#refs.$content.value.scrollBy(xOrOptions as number, y)
      } else {
        this.#refs.$content.value.scrollBy(xOrOptions as ScrollToOptions)
      }
    }
  }

  protected render() {
    return html` <div
      class="one-ux-element--root root"
      style=${styleMap({
        'padding-bottom': this.#positionController.gutterX ? 'var(--one-ux-scroll-element--scrollbar-size)' : null,
        'padding-right': this.#positionController.gutterY ? 'var(--one-ux-scroll-element--scrollbar-size)' : null
      })}
    >
      <div
        ${ref(this.#refs.$content)}
        class="content"
        @scroll=${(event: Event) => this.dispatchEvent(new Event('scroll', event))}
      >
        <slot></slot>
      </div>
      <div
        class=${classMap({
          'show-x': this.#positionController.showX,
          'show-y': this.#positionController.showY
        })}
      >
        <div class="scrollbar scrollbar-y">
          <div
            ${ref(this.#refs.$scrollbarY)}
            class="scrollbar-track scrollbar-track-y"
            @wheel=${{
              passive: false,
              handleEvent: this.#mouseWheelHandler.handleY
            }}
            @pointerdown=${this.#mouseDownHandler.handleY}
          >
            <div
              ${ref(this.#refs.$handleY)}
              class="scrollbar-thumb scrollbar-thumb-y"
              style=${styleMap({
                top: this.#positionController.top + '%',
                height: this.#positionController.height + '%'
              })}
            ></div>
          </div>
        </div>
        <div class="scrollbar scrollbar-x">
          <div
            ${ref(this.#refs.$scrollbarX)}
            class="scrollbar-track scrollbar-track-x"
            @wheel=${{
              passive: false,
              handleEvent: this.#mouseWheelHandler.handleX
            }}
            @pointerdown=${this.#mouseDownHandler.handleX}
          >
            <div
              ${ref(this.#refs.$handleX)}
              class="scrollbar-thumb scrollbar-thumb-x"
              style=${styleMap({
                left: this.#positionController.left + '%',
                width: this.#positionController.width + '%'
              })}
            ></div>
          </div>
        </div>
        <div class="scrollbar-corner"></div>
      </div>
    </div>`
  }
}

export type ChildRefs = {
  $content: Ref
  $scrollbarY: Ref
  $scrollbarX: Ref
  $handleY: Ref
  $handleX: Ref
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-scroll': OneUxScrollElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-scroll': OneUxScrollElement
    }
  }
}
