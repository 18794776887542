import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    appearance: none;
    display: inline-block;
    box-sizing: border-box;
    font-size: 14px;
    width: 1em;
    aspect-ratio: 1;
    background-color: transparent;
    background-image: none;
    border: 0.2em solid var(--one-ux-palette--brand-neutral-600);
    border-top-color: transparent;
    border-radius: var(--one-ux-radius--max);

    transform-origin: center;
    transform: rotate(60deg);
    animation: rotate 800ms infinite cubic-bezier(0.45, 0.05, 0.81, 0.94);

    &::-webkit-progress-bar,
    &::-webkit-progress-value {
      background-color: transparent;
    }

    &::-moz-progress-bar {
      background-color: transparent;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(420deg);
    }
  }
`
