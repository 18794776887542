export class ChangeEvent extends Event {
  static get eventName() {
    return 'change'
  }

  constructor() {
    super(ChangeEvent.eventName, {
      bubbles: false,
      cancelable: false,
      composed: false
    })
  }
}
