import { ContextConsumer, provide } from '@lit/context'
import { html, PropertyValues } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import './InternalOneUxTabsIndicator.js'
import { tabsNextContext, ITabsNextContext } from './TabsNextContext.js'
import { Implicit } from '../../mixins/Implicit.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { Weight } from '../../mixins/Weight.js'
import { OneUxElement } from '../../OneUxElement.js'
import { maskStart, maskStretch, style } from './style.js'
import { Label } from '../../mixins/Label.js'
import { SlotController } from '../../controllers/SlotController.js'
import { setOverflow } from './setOverflow.js'
import { getLanguage } from './language.js'
import { UpdateOnResizedController } from '../../controllers/UpdateOnResizedController.js'
import { keyCodes, scrollElementIntoView } from '../../utils.js'
import { validEdges, type direction, type validEdge } from './types.js'
import { log } from '../../utils/log.js'
import { tabpanelContext } from '../one-ux-tabpanel-next/TabPanelContext.js'
import { MutexContext, mutexReadContext, mutexWriteContext } from '../../contexts/MutexContext.js'
import { OneUxTabNextElement } from '../one-ux-tab-next/OneUxTabNextElement.js'
import { OneUxTabpanelNextElement } from '../one-ux-tabpanel-next/OneUxTabpanelNextElement.js'
import { InternalOneUxTabsIndicator } from './InternalOneUxTabsIndicator.js'
import { keyCode } from '../../../playwright/utils.js'
import { ActiveEvent, BeforeActiveEvent } from './events.js'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const BaseClass = Label(Weight(Implicit(Focusable(Styled(OneUxElement)))))

@customElement('one-ux-tabs-next')
export class OneUxTabsNextElement extends BaseClass {
  public get activeTab() {
    const $active = this.#mutexContext.current
    return $active?.name || ''
  }
  public setActiveTab(value: string) {
    const $tab = this.#slots.getDefaultSlotElements<OneUxTabNextElement>().find(($el) => $el.name === value)
    if ($tab) {
      this.#mutexContext.force($tab)
    }
  }

  @property({ type: Array, attribute: 'collapse-edge' })
  public accessor collapseEdge: validEdge[] = []

  /** @internal */
  @state()
  accessor _currentScrollDirection: direction | 'none' = 'none'

  /** @internal */
  @state()
  accessor _tabNames: string[] = []

  @query('.tablist-nav')
  private accessor _$tablistNav: HTMLDivElement | undefined = undefined

  @query('[role="tablist"]')
  private accessor _$tablist!: HTMLDivElement

  @provide({ context: tabsNextContext })
  private _tabsContext: ITabsNextContext = {
    beforeActivate: this.#beforeTabActivation.bind(this),
    activated: this.#tabActivated.bind(this),
    namedChanged: this.#updateTabNames.bind(this),
    getTabpanelElement: this.#getTabpanelElement.bind(this),
    getActiveIndicator: this.#getActiveIndicator.bind(this),
    weight: this.weight,
    implicit: this.implicit,
    hasAccessibilityStyling: false,
    hasFixedContent: false
  }

  #mutexContext = new MutexContext<OneUxTabNextElement>()
  @provide({ context: mutexWriteContext<OneUxTabNextElement>() })
  private _mutexWriteContext = this.#mutexContext

  @provide({ context: mutexReadContext<OneUxTabNextElement>() })
  private _mutexReadContext = this.#mutexContext

  #tabpanelContext: ContextConsumer<typeof tabpanelContext, this> | null = null

  constructor() {
    super()
    new UpdateOnResizedController(this)

    this.#tabpanelContext = new ContextConsumer(this, {
      context: tabpanelContext,
      callback: (context) => context.registerTabs(),
      subscribe: true
    })

    this.#mutexContext.notify((previousActiveTab) => {
      this.#previousActiveTab = previousActiveTab
      this._currentScrollDirection = 'none'
      this.#setTabIndexOnActiveChange(previousActiveTab)
      requestAnimationFrame(() => this.#scrollIntoView(this.#mutexContext.current!))
    })

    this.addEventListener('blur', this.#setTabIndexOnBlur, { capture: true })
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()
    this.#tabpanelContext?.value?.unregisterTabs()
  }

  #setTabIndexOnActiveChange(previousActiveTab: OneUxTabNextElement | null) {
    if (previousActiveTab) {
      previousActiveTab.tabIndex = -1
    }

    if (this.#mutexContext.current) {
      this.#mutexContext.current.tabIndex = 0
    }
  }

  #setTabIndexOnBlur(event: FocusEvent) {
    const $lostFocus = event.target as HTMLElement
    const $nextFocus = event.relatedTarget as HTMLElement

    const $tabs = this.#slots.getDefaultSlotElements()
    if ($tabs.includes($nextFocus)) {
      $nextFocus.tabIndex = 0
    }

    if ($tabs.includes($lostFocus)) {
      $lostFocus.tabIndex = -1
    }

    if (!$tabs.includes($nextFocus) && this.#mutexContext.current) {
      this.#mutexContext.current.tabIndex = 0
    }
  }

  protected willUpdate(changed: PropertyValues): void {
    if (!this.hasUpdated) {
      this.#updateTabNames()
    }

    if (changed.has('implicit') || changed.has('weight')) {
      this.#updateTabsContext({
        implicit: this.implicit,
        weight: this.weight
      })
    }

    if (changed.has('hasKeyboardFocus')) {
      this.#updateTabsContext({
        hasAccessibilityStyling:
          this.hasKeyboardFocus && this.#slots.getDefaultSlotElements().some(($el) => $el === document.activeElement)
      })
    }
  }

  protected guardedRender() {
    const { languageKey, languageSet } = getLanguage(this)

    return html`<div class="one-ux-element--root" lang=${languageKey}>
      <div
        class=${classMap({
          header: true,
          'has-header-end-content': this.#slots.hasNamedSlot('header-end')
        })}
        role="group"
        aria-label=${languageSet.header}
      >
        <div class="tablist-nav">
          <one-ux-button
            aria-hidden="true"
            tabindex="-1"
            label=${languageSet.scrollLeft}
            class="nav-left"
            weight="low"
            implicit
            @click=${() => this.#scroll('left')}
          >
            <one-ux-icon icon="toggle-left"></one-ux-icon>
          </one-ux-button>
          <div
            role="tablist"
            aria-label=${this.label}
            @scroll=${this.#handleTablistScroll}
            scroll-direction=${this._currentScrollDirection}
            @keydown=${this.#handleKeydown}
          >
            <internal-one-ux-tabs-indicator variant="active"></internal-one-ux-tabs-indicator>
            <slot></slot>
          </div>
          <one-ux-button
            aria-hidden="true"
            tabindex="-1"
            label=${languageSet.scrollRight}
            class="nav-right"
            weight="low"
            implicit
            @click=${() => this.#scroll('right')}
          >
            <one-ux-icon icon="toggle-right"></one-ux-icon>
          </one-ux-button>
        </div>
        <slot name="header-end"></slot>
      </div>

      <div class="tabpanel-container" state-collapse-edge=${ifDefined(this.#getCollapseEdge())}>
        ${this._tabsContext.hasFixedContent
          ? html`<slot name="fixed-content"></slot>`
          : this._tabNames.map((name) => html`<slot name=${name}></slot>`)}
      </div>
    </div>`
  }

  protected firstUpdated(): void {
    this.#ensureActiveElement()

    this.#checkFixedContent()
    this.addEventListener('slotchange', () => {
      this.#checkFixedContent()
    })
  }

  #checkFixedContent() {
    this.#updateTabsContext({
      hasFixedContent: this.#slots.hasNamedSlot('fixed-content')
    })
  }

  #ensureActiveElement() {
    requestAnimationFrame(() => {
      if (!this.#mutexContext.current) {
        this.#mutexContext.lockFirst()
      }
    })
  }

  protected updated(_changed: PropertyValues<this>): void {
    // TODO: do this only when necessary
    if (this._$tablistNav) {
      setOverflow(this._$tablistNav)
    }
  }

  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['header-end', 'fixed-content'],
    allowDynamicSlots: true
  })

  #updateTabsContext(updatedContext: Partial<ITabsNextContext>) {
    this._tabsContext = {
      ...this._tabsContext,
      ...updatedContext
    }
  }

  #updateTabNames() {
    this._tabNames = this.#slots
      .getDefaultSlotElements<OneUxTabNextElement>()
      .filter(($tab) => !!$tab.name)
      .map(({ name }) => name!)
  }

  #getTabpanelElement(name?: string) {
    if (!name) return null

    const slot = this._tabsContext.hasFixedContent ? 'fixed-content' : name
    const $tabpanels = this.#slots.getNamedSlotElements<OneUxTabpanelNextElement>(slot)
    return $tabpanels[0] ?? null
  }

  #getActiveIndicator() {
    return this.shadowRoot!.querySelector<InternalOneUxTabsIndicator>('internal-one-ux-tabs-indicator')
  }

  #getCollapseEdge() {
    if (!this.collapseEdge?.length) {
      return undefined
    }

    for (const edge of this.collapseEdge) {
      if (!(validEdges as unknown as string[]).includes(edge)) {
        log.warning(`Attribute "collapse-edge" can only be ${validEdges.join()}, ignoring provided value "${edge}".`)
      }
    }

    return this.collapseEdge.join(' ')
  }

  #scroll(direction: direction) {
    this._currentScrollDirection = direction

    const $scrollElements = this.#slots.getDefaultSlotElements()
    const $partlyHiddenTab =
      direction === 'left'
        ? $scrollElements.toReversed().find(($el) => this.#isHiddenToLeft($el))
        : $scrollElements.find(($tab) => this.#isHiddenToRight($tab))

    if (!$partlyHiddenTab) return

    const tablistRect = this._$tablist.getBoundingClientRect()
    const partlyHiddenTabRect = $partlyHiddenTab.getBoundingClientRect()
    const scrollAmount =
      direction === 'left'
        ? partlyHiddenTabRect.right - tablistRect.left - partlyHiddenTabRect.width - maskStart
        : partlyHiddenTabRect.left - tablistRect.left - tablistRect.width + partlyHiddenTabRect.width + maskStart

    if (scrollAmount) {
      this._$tablist.scrollBy({ left: scrollAmount })
      this.#scrollByButton = true
    }
  }

  #isHiddenToLeft($el: HTMLElement) {
    const leftEdge = $el.getBoundingClientRect().left - this._$tablist.getBoundingClientRect().left
    return leftEdge < 0
  }

  #isHiddenToRight($el: HTMLElement) {
    const tablistRect = this._$tablist.getBoundingClientRect()
    const rightEdge = $el.getBoundingClientRect().right - tablistRect.left
    return rightEdge > tablistRect.width
  }

  #isPartlyHidden($el: HTMLElement) {
    return this.#isHiddenToLeft($el) || this.#isHiddenToRight($el)
  }

  #scrollByButton = false

  #scrollTimeoutId: ReturnType<typeof setTimeout> | undefined
  #scrollOutOfViewTimeoutId: ReturnType<typeof setTimeout> | undefined
  #handleTablistScroll() {
    if (this.#scrollByButton) {
      clearTimeout(this.#scrollTimeoutId)
    }

    this.#scrollTimeoutId = setTimeout(() => {
      if (!this.#scrollByButton) {
        this._currentScrollDirection = 'none'
      }

      this.#scrollByButton = false
    }, 100)

    if (this._$tablistNav) {
      setOverflow(this._$tablistNav)
    }

    if (this.#scrollOutOfViewTimeoutId) {
      clearTimeout(this.#scrollOutOfViewTimeoutId)
    }

    const scrollIdleTimeout = 5000
    this.#scrollOutOfViewTimeoutId = setTimeout(() => {
      if (!this.#isPartlyHidden(this.#mutexContext.current!)) {
        return
      }
      this._currentScrollDirection = 'none'
      this.#scrollIntoView(this.#mutexContext.current!)
    }, scrollIdleTimeout)
  }

  #previousActiveTab: OneUxTabNextElement | null = null

  #beforeTabActivation(name: string) {
    return this.dispatchEvent(new BeforeActiveEvent(name))
  }

  #tabActivated() {
    this.dispatchEvent(new ActiveEvent())
  }

  #scrollIntoView($el: HTMLElement) {
    scrollElementIntoView(this._$tablist, $el, 'horizontal', maskStretch)
  }

  #handleKeydown(event: KeyboardEvent) {
    const handled = () => {
      event.preventDefault()
      event.stopPropagation()
    }

    const $defaultSlotElements = this.#slots.getDefaultSlotElements<OneUxTabNextElement>()
    const currentFocusIndex = $defaultSlotElements.findIndex(($tab) => $tab === document.activeElement)

    const focusTab = (index: number) => {
      $defaultSlotElements[index].tabIndex = 0
      $defaultSlotElements[index].focus()
      handled()
    }

    const isFirstTab = currentFocusIndex === 0
    const lastTabIndex = $defaultSlotElements.length - 1
    const isLastTab = currentFocusIndex === lastTabIndex

    switch (event.code) {
      case keyCode.Space:
      case keyCode.Enter:
      case keyCode.NumpadEnter:
        $defaultSlotElements[currentFocusIndex].click()
        return handled()
      case keyCodes.LEFT:
        return focusTab(isFirstTab ? currentFocusIndex : currentFocusIndex - 1)
      case keyCodes.RIGHT:
        return focusTab(isLastTab ? currentFocusIndex : currentFocusIndex + 1)
      case keyCodes.HOME:
        return focusTab(0)
      case keyCodes.END:
        return focusTab(lastTabIndex)
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tabs-next': OneUxTabsNextElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tabs-next': OneUxTabsNextElement
    }
  }
}
