import { type AccessibilityTreeSnapshot } from './AccessibilityTreeSnapshot/AccessibilityTreeSnapshot.js'

export class AccessibilityService {
  #snapshotHandler?: AccessibilityTreeSnapshot

  async snapshot(node?: Node) {
    if (!this.#snapshotHandler) {
      const { AccessibilityTreeSnapshot } = await import('./AccessibilityTreeSnapshot/AccessibilityTreeSnapshot')
      this.#snapshotHandler = new AccessibilityTreeSnapshot()
    }
    this.#snapshotHandler.create(node)
  }
}
