import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Label } from '../../mixins/Label.js'
import { getLangCode } from '../../utils/getLangCode.js'
import { strings } from './language.js'

const Styled = StyledFactory(style)

const BaseClass = Label(Styled(OneUxElement))

@customElement('one-ux-busy')
export class OneUxBusyElement extends BaseClass {
  protected render() {
    const lang = getLangCode(this)
    const label = this.label || strings[lang].loading

    return html`<progress class="one-ux-element--root" aria-label=${label}></progress>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-busy': OneUxBusyElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-busy': OneUxBusyElement
    }
  }
}
