import { createContext } from '@lit/context'

export interface ITabpanelContext {
  registerTabs: () => void
  unregisterTabs: () => void
}

const contextKey = Symbol('one-ux-tabpanel-context')

export const defaultTabpanelContext: ITabpanelContext = {
  registerTabs() {
    return
  },
  unregisterTabs() {
    return
  }
}

export const tabpanelContext = createContext<ITabpanelContext>(contextKey)
