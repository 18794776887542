import { createContext } from '@lit/context'
import { OneUxTabpanelNextElement } from '../one-ux-tabpanel-next/OneUxTabpanelNextElement.js'
import { Nullable } from '../../types.js'
import { InternalOneUxTabsIndicator } from './InternalOneUxTabsIndicator.js'
import { weight } from '../../mixins/Weight.js'

export interface ITabsNextContext {
  beforeActivate(name: string): boolean
  activated(): void
  namedChanged(): void
  getTabpanelElement(name: string): Nullable<OneUxTabpanelNextElement>
  getActiveIndicator(): Nullable<InternalOneUxTabsIndicator>

  weight: weight
  implicit: boolean
  hasAccessibilityStyling: boolean
  hasFixedContent: boolean
}

const contextKey = Symbol('one-ux-tabs-context')

export const defaultTabsNextContext: ITabsNextContext = {
  beforeActivate(_name: string) {
    return true
  },
  activated() {},
  namedChanged() {},
  getTabpanelElement(_name: string) {
    return null
  },
  getActiveIndicator() {
    return null
  },

  weight: 'normal',
  implicit: false,
  hasAccessibilityStyling: false,
  hasFixedContent: false
}

export const tabsNextContext = createContext<ITabsNextContext>(contextKey)
